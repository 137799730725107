<template>
  <div>
    <div class="container">
      <div class="idx-content">
        <!-- 面包屑导航 -->
        <div class="class-nav">
          当前位置：
          <router-link tag="a" to="/">首页</router-link>
          > 我的课程
        </div>
        <!-- <img src="@/assets/image/cqc-bn.jpg" alt="" /> -->
        <!-- 平台课程列表 -->
        <!-- <div class="idx-course-list">
        <div class="idx-course-list-title"><span></span>平台课程列表</div>
      </div> -->
        <!-- 平台课程切换列表 -->
        <div class="idx-course-list">
          <div class="idx-course-listbox">
            <div class="idx-course-list-title">
              <span></span>我的课程 <span class="idx-s2"></span>
            </div>
            <div class="idx-course-tab">
              <div class="idx-tab-list" v-for="item in zero" :key="item.id" @click="toDetail(item.id)">
                <div class="idx-tab-list-1">
                  <div class="image">
                    <img :src="item.imageUrl || selectDefaultImg(item.id)" @error="error(item.id)" alt/>
                    <div class="state" v-if="item.courseType === '2'">直播</div>
                  </div>
                  <div class="idx-R-1" :title="item.title">{{ item.title }}</div>
                  <div class="idx-R-2" v-if="item.courseType === '2'">
                    <div>
                      <!--                      <img src="@/assets/icon/meditor-time.png" alt="">-->
                      <span class="idx-R2-s2">{{ item.courseStartTime }}至{{ item.courseEndTime }}</span>
                    </div>
                    <!--                    <div>-->
                    <!--                      <img src="@/assets/icon/meditor-time.png" alt="">-->
                    <!--                      <span class="idx-R2-s2">{{ item.courseEndTime }}</span>-->
                    <!--                    </div>-->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {listUserCourse, listTeacherCourse} from "@/api/bm/course";
import Vue from 'vue';
import {domain} from "../../utils/request";
// import { signTeacher, signStudent } from "@/api/bm/student";
export default {
  data() {
    return {
      activeName: "first",
      selected: -1,
      statusOptions: [
        {
          dictValue: 0,
          dictLabel: "待上传凭证",
        },
        {
          dictValue: 1,
          dictLabel: "待寄出发票",
        },
        {
          dictValue: 2,
          dictLabel: "待确认收款",
        },
        {
          dictValue: 3,
          dictLabel: "已完成",
        },
        {
          dictValue: 4,
          dictLabel: "已驳回",
        },
        {
          dictValue: 5,
          dictLabel: "已取消",
        },
      ],
      zero: [],
      one: [],
      two: [],
      other: [],
      total: 0,
      wayOptions: undefined,
      queryCourse: {
        pageNum: 1,
        pageSize: 10,
        courseType: undefined,
        courseName: undefined
      },
    };
  },
  methods: {
    error(id) {
      let img = event.srcElement;
      img.src = Vue.prototype.selectDefaultImg(id)
      img.onerror = null; //防止闪图
    },
    toDetail(id) {
      console.log(id);
      window.open(
          // "http://guangzhou.baikegz.com/cqc-baike-web/platformCourseDetail?id=" + id,
          // "http://113.108.126.152:3098/cqc-baike-web/platformCourseDetail?id=" + id,
          domain + "platformCourseDetail?id=" + id,
          "_blank"
      );
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    setSelected(selected) {
      this.selected = selected;
    },
    getList() {
      listTeacherCourse({isDel: 'N'}).then((res) => {
        this.total = res.total;
        //   this.dealList(res.rows);
        this.zero = res.rows
      });
    },
    dealList(rows) {
      let zero = [],
          one = [],
          two = [],
          other = [];
      for (let i = 0; i < rows.length; i++) {
        if (rows[i].status == 0 || rows[i].status == 4) {
          zero.push(rows[i]);
        } else if (rows[i].status == 1) {
          one.push(rows[i]);
          zero.push(rows[i]);
          //
          other.push(rows[i]);
        } else if (rows[i].status == 2) {
          two.push(rows[i]);
          zero.push(rows[i]);
        } else {
          other.push(rows[i]);
          zero.push(rows[i]);
        }
      }
      this.zero = zero;
      this.one = one;
      this.two = two;
      this.other = other;
    },
    wayFormat(k) {
      return this.selectDictLabel(this.wayOptions, k);
    },
    statusFormat(k) {
      return this.selectDictLabel(this.statusOptions, k);
    },
  },
  created() {
    this.getList();
    // this.getSignup();
    this.getDicts("bm_course_way").then((res) => {
      this.wayOptions = res.data;
    });
  },
};
</script>

<style scoped lang="scss">
.container {
  height: auto;
  background: #f8f8f8;
  //overflow: hidden;
  padding-top: 22px;

  .idx-content {
    width: 100%;
    max-width: 1300px;
    margin: 0 auto;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: auto;
    // text-align: center;
    img {
      width: 1300px;
      height: 450px;
    }

    .idx-course-list {
      width: 1300px;
      height: auto;
      background: #ffffff;
      margin: 40px 0 40px 0;
      padding: 10px;

      padding-bottom: 138px;

      .idx-course-listbox {
        margin-top: 72px;
        position: relative;
        //overflow: hidden;
        // display: flex;
        // flex-direction: row;
        // justify-content: flex-start;
        // align-items: center;
        ::v-deep .el-tabs__nav-wrap {
          overflow: hidden;
          margin-bottom: -1px;
          position: relative;
          padding-left: 247px;
        }

        ::v-deep .el-tabs__item {
          padding: 0 20px;
          height: 40px;
          -webkit-box-sizing: border-box;
          box-sizing: border-box;
          line-height: 25px;
          display: inline-block;
          list-style: none;
          font-size: 18px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #2c2c2c;
          position: relative;
        }

        ::v-deep .el-tabs__item.is-active {
          color: #409eff;
        }

        ::v-deep .el-tabs__active-bar {
          position: absolute;
          bottom: 0;
          left: 0;
          height: 4px;
          background-color: #409eff;
          z-index: 1;
          -webkit-transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
          -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          transition: transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1),
          -webkit-transform 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
          list-style: none;
        }

        .idx-course-list-title {
          font-size: 23px;
          font-family: Microsoft YaHei;
          font-weight: bold;
          color: #28333c;
          //   position: absolute;
          //   top: 1px;
          //   background: red;
          span {
            width: 5px;
            height: 25px;
            background: #2979ea;
            border-radius: 3px;
            float: left;
            margin-right: 10px;
          }

          .idx-s2 {
            width: 1px;
            height: 21px;
            background: #fff;
            float: right;
            margin-left: 52px;
          }
        }

        .idx-course-tab {
          width: 1400px;
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin-top: 10px;

          .signHide {
            display: none;
          }

          .idx-tab-list {
            width: 300px;
            height: 270px;
            margin-right: 26px;
            margin-top: 10px;
            margin-bottom: 10px;
            cursor: pointer;
            -webkit-transition: all 0.3s linear;
            transition: all 0.3s linear;
            background: #FFFFFF;
            box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.1);
            border-radius: 6px;
            overflow: hidden;

            &:hover {
              background: #ffffff;
              box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.1);
              border-radius: 12px 12px 6px 6px;
              transition: all 0.3s linear;
            }

            // border-radius: 12px;
            .idx-tab-list-1 {
              //   border: 1px solid teal;
              height: 180px;
              position: relative;

              .image {
                width: 100%;
                height: 180px;
                position: relative;

                img {
                  width: 100%;
                  height: 100%;
                }

                .state {
                  position: absolute;
                  right: 0;
                  top: 0;
                  width: 64px;
                  height: 30px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background: #00995E;
                  font-size: 15px;
                  font-family: Microsoft YaHei;
                  font-weight: 400;
                  color: #FFFFFF;
                }
              }

              .idx-R-1 {
                width: calc(100% - 24px);
                padding: 18px 0 15px;
                margin: 0 12px;
                font-size: 16px;
                font-family: Microsoft YaHei;
                font-weight: 400;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                border-bottom: 1px solid #E9E9E9;
              }

              .idx-R-2 {
                width: calc(100% - 24px);
                padding: 16px 0;
                margin: 0 12px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                div {
                  display: flex;
                  align-items: center;

                  &:first-child {
                    margin-bottom: 8px;
                  }

                  img {
                    width: 16px;
                    height: 16px;
                    display: block;
                  }

                  .idx-R2-s1 {
                    float: left;
                    width: 92px;
                    height: 25px;
                    line-height: 25px;
                    text-align: center;
                    background: #dfe5e9;
                    border-radius: 3px;
                    font-size: 15px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #808085;
                    margin-left: 16px;
                  }

                  .idx-R2-s1-n {
                    background: #5695f1;
                    color: #fff;
                  }

                  .idx-R2-s2 {
                    font-size: 12.9px;
                    font-family: Microsoft YaHei;
                    font-weight: 400;
                    color: #000000;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
